import { XIcon } from "@heroicons/react/outline";
import React from "react";

export const TranscriptFallbackBanner = ({ isVisible, setIsVisible }) => {
  return (
    <div
      className={`fixed top-[10%] w-1/2 flex justify-center items-center pb-2 sm:pb-5 z-50 ${
        !isVisible ? "hidden" : "block"
      }`}
    >
      <div className="p-2 bg-white rounded-lg shadow-lg sm:p-3 mx-auto text-xs">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center flex-1">
            <p className="ml-3 font-medium text-gray-500 mr-4">
              <span>
                There was a small error. <br />
                The transcript display is currently using placeholder data. <br />
                This feature may be limited or incorrect.
              </span>
            </p>
          </div>
          <div className="flex-shrink-0 order-2 sm:order-3 sm:ml-2">
            <button
              type="button"
              className="flex -mr-1 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-white"
              onClick={() => setIsVisible(false)}
            >
              <XIcon className="w-6 h-6 text-gray-500" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};