// import { Fragment } from "react";
// import { selectedTabState } from "../../../recoil/atoms/general/selectedTab";
// import { selectedCourseState } from "../../../recoil/atoms/general/selectedCourse";
// import { useSetRecoilState } from "recoil";
// import { StatusElement } from "./StatusElement";
import { TranscriptTable } from "./TranscriptTable";
// import Tooltip from "../util/Tooltip";
import { TranscriptFallbackBanner } from "../../../../utils/other/TranscriptFallbackBanner";
import { fallbackState } from "../../../recoil/atoms/general/fallbackState";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";

export default function Transcript({ currItem }) {
  const [isFallback, setIsFallback] = useRecoilState(fallbackState);
  useEffect(() => {
    setIsFallback(currItem?.fallbackUsed || false);
  }, [currItem, setIsFallback]);

  // Render the fallback banner if `isFallback` is true
  return (
    <>
      <TranscriptFallbackBanner
        isVisible={isFallback}
        setIsVisible={setIsFallback}
      />

      <div className="flex flex-col p-2">
        <div className="w-full h-full">
          <div className="inline-block w-full h-full min-w-full align-middle">
            <div className="pb-3 overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <TranscriptTable row={currItem} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}