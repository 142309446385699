import React from "react";
import { selectedTabState } from "../../../recoil/atoms/general/selectedTab";
import { useRecoilValue, useSetRecoilState } from "recoil";
// import { StatusElement } from "../transcript/StatusElement";
import { selectedCourseState } from "../../../recoil/atoms/general/selectedCourse";
import { scorecardGraduationState } from "../../../recoil/atoms/hsg/scorecardGraduation";
// import Tooltip from "../util/Tooltip";
// import { selectedSemesterState } from "../../../recoil/atoms/general/selectedSemester";
import { selectedSemesterState } from "../../../recoil/atoms/general/selectedSemester";

export default function StudyOverview({ finalCourses, data }) {
  const [finalBySemester, setCoursesBySemester] = React.useState([]);
  const [selectedSemesterKey, setSelectedSemesterKey] = React.useState("");
  const [maxCredits, setMaxCredits] = React.useState(0);
  const setSelectedCourse = useSetRecoilState(selectedCourseState);
  const [hoveredCourse, setHoveredCourse] = React.useState(null);
  const scorecard = useRecoilValue(scorecardGraduationState).items[0];
  const selectedSemester2 = useRecoilValue(selectedSemesterState);
  const setSelectedTab = useSetRecoilState(selectedTabState);

  // remove duplicates from finalBySemester for every semester
  // take finalbysemester, clean it and setCoursesBySemester

  // React.useEffect(() => {
  //   let uniqueCoursesBySemester = [];

  //   if (finalBySemester) {
  //     for (const key in finalBySemester) {
  //       if (finalBySemester.hasOwnProperty(key)) {
  //         const courses = finalBySemester[key];
  //         let uniqueCourses = [];
  //         let uniqueCourseNumbers = [];
  //         courses.forEach((course) => {
  //           if (!uniqueCourseNumbers.includes(course.courseNumber)) {
  //             uniqueCourses.push(course);
  //             uniqueCourseNumbers.push(course.courseNumber);
  //           }
  //         });
  //         uniqueCoursesBySemester[key] = uniqueCourses;
  //       }
  //     }
  //   }
  //   console.debug(uniqueCoursesBySemester);

  //   // console.debug(uniqueCoursesBySemester);
  // }, [finalBySemester]);

  // remove duplicates from semester

  function semesterNameCleanUp(semesterName) {
    let semester = semesterName.replace(" Placeholder", "");
    semester = semester.replace("Spring", "FS");
    semester = semester.replace("Fall", "HS");
    semester = semester.replace("Spring Semester", "FS");
    semester = semester.replace("Fall Semester", "HS");
    semester = semester.replace("Herbstsemester", "HS");
    semester = semester.replace("Frühjahrssemester", "FS");
    semester =
      semester.split(" ")[0] +
      " " +
      semester.split(" ")[1].toString().substring(2, 4);
    return semester;
  }

  React.useEffect(() => {
    let coursesBySemester = [];

    function parseSemester(semesterStr) {
      // e.g. "FS 23" => {year: 2023, sem: 1}, "HS 23" => {year: 2023, sem: 2}
      const [season, shortYear] = semesterStr.split(" ");
      const year = 2000 + parseInt(shortYear, 10); // or 1900 if you need
      const sem = season === "FS" ? 1 : 2;
      return { year, sem };
    }
    
    function formatSemester({ year, sem }) {
      // {year: 2023, sem: 1} => "FS 23", {year: 2024, sem: 2} => "HS 24"
      const season = sem === 1 ? "FS" : "HS";
      // just get last two digits for display
      const yearShort = (year % 100).toString().padStart(2, "0");
      return `${season} ${yearShort}`;
    }

    // console.debug(selectedSemester2);

    // create an array of arrays with the semester as key and the courses as value

    // group testCourses by Semester
    coursesBySemester = finalCourses.reduce((r, a) => {
      r[a.semester] = [...(r[a.semester] || []), a];
      return r;
    }, {});

    // 2. Figure out the earliest & latest semester from the actual data:
    const allSemesterKeys = Object.keys(coursesBySemester);

    if (allSemesterKeys.length > 0) {
      // parse them to numeric values
      const numericSemesters = allSemesterKeys.map((s) => {
        const { year, sem } = parseSemester(s);
        return 2 * year + sem;  
      });
    
      const minSemester = Math.min(...numericSemesters);
      const maxSemester = Math.max(...numericSemesters);
    
      // 3. Generate every integer from min to max:
      for (let i = minSemester; i <= maxSemester; i++) {
        // 4. Convert back into “FS YY” or “HS YY”
        const year = Math.floor(i / 2);
        const sem = i % 2 === 0 ? 2 : 1;
        const semesterStr = formatSemester({ year, sem });
    
        // If that semester key doesn't exist yet, add it as an empty array
        if (!coursesBySemester[semesterStr]) {
          coursesBySemester[semesterStr] = [];
        }
      }
    }

    // console.debug('help1', finalCourses)

    // find the semester with the highest sum of credits and store the credits in maxSemester
    let maxCredits = 0;
    Object.keys(coursesBySemester).forEach((semester) => {
      let sumOfCredits = 0;
      coursesBySemester[semester].forEach((course) => {
        sumOfCredits += parseFloat(course.sumOfCredits);
      });
      if (sumOfCredits > maxCredits) {
        maxCredits = sumOfCredits;
        // maxSemester = semester;
      }
    });

    setMaxCredits(maxCredits);

    // sort semesters by year and spring / fall
    coursesBySemester = Object.keys(coursesBySemester)
      .sort((a, b) => {
        const yearA = parseInt(a.split(" ")[1]);
        const yearB = parseInt(b.split(" ")[1]);
        const semesterA = a.split(" ")[0];
        const semesterB = b.split(" ")[0];

        if (yearA < yearB) {
          return -1;
        }
        if (yearA > yearB) {
          return 1;
        }
        if (semesterA === "FS") {
          return -1;
        }
        if (semesterB === "FS") {
          return 1;
        }
        return 0;
      })
      .reduce((r, k) => {
        r[k] = coursesBySemester[k];
        return r;
      }, {});

    function getLowestLevel(topScorecard) {
      if (
        topScorecard.items &&
        topScorecard.items.filter((item) => item.isTitle).length > 0
      ) {
        return topScorecard.items.map((item) => getLowestLevel(item));
      } else {
        return topScorecard.description;
      }
    }

    const scorecardClassifications = {};
    (scorecard && scorecard.items ? scorecard.items : []).forEach((classification) => {
      const result = getLowestLevel(classification);

      if (Array.isArray(result)) {
        scorecardClassifications[classification.description] = [
          ...result,
        ].flat();
      } else {
        scorecardClassifications[classification.description] = [result];
      }
    });

    console.log("scorecardClassifications", scorecardClassifications);

    const classificationDict = {};
    Object.keys(scorecardClassifications).forEach((key) => {
      scorecardClassifications[key].forEach((item) => {
        classificationDict[item] = key;
      });
    });

    const coursesWithTopHierarchy = {};

    Object.keys(coursesBySemester).forEach((key) => {
      coursesWithTopHierarchy[key] = coursesBySemester[key].map((course) => {
        return {
          ...course,
          topHierarchy: classificationDict[course.classification]
            ? classificationDict[course.classification]
            : "Other",
        };
      });
    });

    // delete object with key "" (empty string)
    delete coursesWithTopHierarchy[""];

    // console.debug('help', coursesWithTopHierarchy)
    setCoursesBySemester(coursesWithTopHierarchy);

    // setCoursesBySemester(coursesBySemester);
  }, [finalCourses, scorecard]);

  function getSumOfCredits(semester) {
    let sum = 0;
    finalBySemester[semester].forEach((course) => {
      sum += parseFloat(course.sumOfCredits);
    });
    return sum;
  }

  function summarizeSemester(semester) {
    return finalBySemester[semester].reduce(
      (accumulator, currentValue) => {
        if (currentValue.grade) {
          accumulator.sumOfCredits =
            accumulator.sumOfCredits + parseFloat(currentValue.sumOfCredits);
          accumulator.grade =
            accumulator.grade +
            parseFloat(currentValue.grade) *
            parseFloat(currentValue.sumOfCredits);
        }
        return accumulator;
      },
      { sumOfCredits: 0, grade: 0 }
    );
  }

  function calculateAverage(semester) {
    const semesterSummary = summarizeSemester(semester);
    return (semesterSummary.grade / semesterSummary.sumOfCredits).toFixed(2);
  }

  // replaced by calculateAverage() & summarizeSemester()
  // function getAverageGrade(semester) {
  //     console.log("SEMESTER", semester);
  //     console.log("finalBySemester", finalBySemester);
  //     let sum = 0;
  //     let total = getSumOfCredits(semester);
  //     finalBySemester[semester].forEach((course) => {
  //         if (
  //             course.grade &&
  //             course.grade !== null &&
  //             course.maxCredits > 0 &&
  //             course.name !== "Campus Credits" &&
  //             course.sumOfCredits > 0
  //         ) {
  //             sum +=
  //                 parseFloat(course.grade) * parseFloat(course.sumOfCredits);
  //         }
  //     });
  //     return (sum / total).toFixed(2);
  // }

  // return selected semester using the selectedSemesterKey
  const selectedSemester = finalBySemester[selectedSemesterKey];

  // make a list of all classifications in all semesters
  const allClassifications = [];

  Object.keys(finalBySemester).forEach((semester) => {
    finalBySemester[semester].forEach((course) => {
      // console.debug("newby", course)
      if (isPlaceholder(course) && !allClassifications.includes("Placeholder")) {
        allClassifications.push("Placeholder")
      }
      else if (!allClassifications.includes(course.topHierarchy)) {
        allClassifications.push(course.topHierarchy);
      }
    });
  });

  // console.debug("allClassifications", allClassifications);

  function returnIndexOfClassification(classification) {
    return allClassifications.indexOf(classification);
  }

  const colors = ["main", "main opacity-30", "red-800 opacity-30", "neutral"];

  function setColorBasedOnClassification(classification) {
    const index = returnIndexOfClassification(classification);
    switch (index) {
      case 0:
        return colors[0];
      case 1:
        return colors[1];
      case 2:
        return colors[2];
      default:
        return colors[3];
    }
  }

  // console.debug("finalBySemester", finalBySemester);

  function isPlaceholder(course) {
    if (course.fullEvent && course.fullEvent.id && course.fullEvent.id.startsWith("PLACEHOLDER") && (course.topHierarchy === allClassifications[0] | course.topHierarchy === allClassifications[1])) {
      // console.debug("newby1", allClassifications)
      return true
    }
    return false
  }
  return (
    <div className="flex flex-col px-8 py-4">
      <div className="py-2 pl-2 text-xl font-bold bg-gray-100 rounded">
        {data.description}
      </div>
      {/* <div>
        Get an overview of a specific semester by clicking on its name on the
        left of the bars. For the current and future semesters you can find an
        even more detailed overview in the "Semester Summary" tab.
      </div> */}
      <div className="px-1 py-2">
        <div className="flex flex-col w-full">
          <div className="px-2 py-1 pb-3 ring-1 ring-black ring-opacity-5 md:rounded-lg">
            {finalBySemester && Object.keys(finalBySemester).length > 0 ? (
              Object.keys(finalBySemester).map((semester, index) => {
                return (
                  <div
                    className="grid grid-cols-10 gap-4"
                    onMouseEnter={() => setSelectedSemesterKey(semester)}
                  >
                    <div
                      onClick={() => setSelectedSemesterKey(semester)}
                      className={`my-auto font-semibold lg:font-bold text-sm lg:text-base cursor-pointer ${semester === selectedSemesterKey ? "text-hsg-800" : ""
                        }`}
                    >
                      {semester}
                    </div>

                    <div className="flex flex-row col-span-7 ">
                      {finalBySemester[semester].map((course, index) => {
                        return (
                          <div
                            // className={courseStatusFormat(course)}
                            className={`h-8 m-1 text-xxs hover:shadow-lg rounded items-center text-center flex min-w-24 w-full text-white justify-center border-0 bg-${isPlaceholder(course) ? setColorBasedOnClassification("Placeholder") : setColorBasedOnClassification(
                              course.topHierarchy
                            )}
                            ${course.status === "completed"
                                ? " border-opacity-50 cursor-not-allowed"
                                : course.status === "toBeRated"
                                  ? " "
                                  : ""
                              } ${course.fullEvent && course.fullEvent.overlapping
                                ? "border-warning cursor-pointer "
                                : course.status === "selected"
                                  ? " border-main  cursor-pointer"
                                  : course.status === "allocated"
                                    ? " border-neutral"
                                    : ""
                              }
                            }`}
                            style={{
                              width: `${(course.sumOfCredits / maxCredits) * 100
                                }%`,
                            }}
                            onMouseEnter={() => {
                              setHoveredCourse(course);
                            }}
                            onMouseLeave={() => setHoveredCourse(null)}
                            onMouseDown={() => {
                              if (course.fullEvent) {
                                setSelectedCourse(course.fullEvent);
                                setSelectedTab(0);
                              }
                            }}
                          >
                            {/* {course.topHierarchy
                              ? course.topHierarchy
                              : course.classification} */}
                            {/* {StatusElement(course)} */}
                          </div>
                        );
                      })}
                    </div>
                    <div className="grid items-center h-8 grid-cols-2 col-span-2 gap-4 my-auto text-sm font-semibold text-center align-middle">
                      <div>{getSumOfCredits(semester)} ECTS</div>
                      <div>
                        {calculateAverage(semester) > 0 ? (
                          "Ø " + calculateAverage(semester)
                        ) : semester &&
                          selectedSemester2 &&
                          semester ===
                          semesterNameCleanUp(selectedSemester2.value) ? (
                          <button
                            className="w-full py-2 text-xs font-semibold text-white rounded-md bg-hsg-800"
                            onClick={() => setSelectedTab(2)}
                          >
                            Details
                          </button>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No data</div>
            )}
          </div>
          <div className="flex flex-row items-center justify-center w-full pt-2">
            {allClassifications.map((classification, index) => {
              if (classification) {
                return (
                  <div className="flex items-center pl-4 text-xs font-normal">
                    <div
                      className={`h-4 w-4 rounded shadow-xl px-2 mx-1 text-center items-center align-middle text-white bg-${setColorBasedOnClassification(
                        classification
                      )}`}
                    ></div>
                    <div>{classification}</div>
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </div>
        </div>
      </div>
      <div className="py-2 pl-2 text-xl font-bold bg-gray-100 rounded">
        Overview {selectedSemesterKey}
      </div>
      <div>
        <div className="flex flex-col px-1 py-2">
          <div className="w-full h-full">
            <div className="inline-block w-full h-full min-w-full align-middle">
              <div className="ring-1 ring-black ring-opacity-5 md:rounded-lg ">
                {selectedSemester &&
                  selectedSemester.map((course, index) => {
                    return (
                      <div
                        key={index}
                        className={`px-2 rounded grid grid-cols-10 gap-4 text-gray-900 text-sm py-1 ${hoveredCourse &&
                          course.name === hoveredCourse.name &&
                          course.classification === hoveredCourse.classification
                          ? "bg-neutral bg-opacity-50"
                          : ""
                          }`}
                      >
                        {/* <div className="flex flex-row col-span-1">CourseNr</div> */}
                        <div className="col-span-4 font-semibold truncate">
                          {course.name}
                        </div>
                        <div className="col-span-4 truncate ">
                          {course.classification}
                        </div>
                        <div className="text-center">
                          {course.sumOfCredits} ECTS
                        </div>
                        <div className="text-center">
                          {course.grade ? course.grade.toFixed(2) : "-"}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        {/* {selectedSemesterKey && selectedSemesterKey === "FS 23" ? (
          <button
            className="w-full py-2 mt-2 font-semibold text-white rounded-md bg-hsg-800"
            onClick={() => setSelectedTab(2)}
          >
            {selectedSemesterKey} Summary
          </button>
        ) : null} */}
      </div>
    </div>
  );
}
