import React from "react";
// import { analyticsExamsQuery } from "../../../recoil/selectors/general/analyticsExams";
import { useRecoilValue } from "recoil";
// import { analyticsCreditsQuery } from "../../../recoil/selectors/general/analyticsCredits";
// import Collapsible from "../courseDetails/FocusCollapsible";
// import { addedLocalSelectedCoursesQuery } from "../../../recoil/selectors/general/addedLocalSelectedCourses";
import { selectedCoursesQuery } from "../../../recoil/selectors/general/selelctedCourses";
import { selectedSemesterState } from "../../../recoil/atoms/general/selectedSemester";
import { useRecoilState } from "recoil";
// import { StatusElement } from "../transcript/StatusElement";
import { LockOpen } from "../util/LockOpen";
import { LockClosed } from "../util/LockClosed";
// import Treemap from "./Treemap";
import { selectedTabState } from "../../../recoil/atoms/general/selectedTab";
import { selectedCourseState } from "../../../recoil/atoms/general/selectedCourse";
import { useSetRecoilState } from "recoil";
import { Heatmap } from "./Heatmap";

export function Analytics({ finalCourses }) {
  const setSelectedCourse = useSetRecoilState(selectedCourseState);
  const setSelectedTab = useSetRecoilState(selectedTabState);
  const [courseOnDay, setCourseOnDay] = React.useState([]);
  const [hoveredDate, setHoveredDate] = React.useState(null);

  const selectedCourses = useRecoilValue(selectedCoursesQuery);
  const [selectedSemester] = useRecoilState(selectedSemesterState);
  const [hoveredCourse, setHoveredCourse] = React.useState(null);

  let currCourses = selectedCourses.flat().filter((course) => {
    return course.semesterId === selectedSemester.cisTermId;
  });

  finalCourses = finalCourses.filter((course) => {
    return (
      course.fullEvent &&
      course.fullEvent.semesterId === selectedSemester.cisTermId
    );
  });

  function removeDuplicateCourses(currCourses) {
    const uniqueCourses = [];
    const entityIds = new Set();

    currCourses.forEach((course) => {
      if (!entityIds.has(course.hsgEntityId)) {
        entityIds.add(course.hsgEntityId);
        uniqueCourses.push(course);
      }
    });

    return uniqueCourses;
  }

  const uniqueCourses = removeDuplicateCourses(currCourses);

  const totalCredits = uniqueCourses.reduce((acc, curr) => {
    return acc + curr.credits / 100;
  }, 0);

  const totalEvents = currCourses.reduce((acc, curr) => {
    return acc + curr.calendarEntry.length;
  }, 0);

  function courseSelector(fullEvent) {
    if (fullEvent) {
      setSelectedCourse(fullEvent);
      setSelectedTab(0);
    }
  }

  // restructure currCourses to be an array of objects with the same structure as the targetStructure
  // every achievementPart has a name, category, and value

  const isPaperRegex = new RegExp("paper*|hausarbeit*|schriftlich*|written");
  const isPresentationRegex = new RegExp("präsentation*|vortrag*|presentation");
  const isOralRegex = new RegExp("oral*|mündlich*|speech");
  const isParticipationRegex = new RegExp("participation*|teilnahme*");

  const newCourses = currCourses
    .map((course) => {
      const newExams = course.achievementParts.map((exams) => {
        let newExam = {};
        newExam.courseName = course.shortName;
        newExam.shortName = exams.achievementForm.shortName;
        if (exams.achievementForm.isCentral) {
          newExam.type = "Central";
        } else if (
          isPaperRegex.test(exams.achievementForm.shortName.toLowerCase())
        ) {
          newExam.type = "Paper";
        } else if (
          isPresentationRegex.test(
            exams.achievementForm.shortName.toLowerCase()
          )
        ) {
          newExam.type = "Presentation";
        } else if (
          isOralRegex.test(exams.achievementForm.shortName.toLowerCase())
        ) {
          newExam.type = "Oral";
        } else if (
          isParticipationRegex.test(
            exams.achievementForm.shortName.toLowerCase()
          )
        ) {
          newExam.type = "Participation";
        } else {
          newExam.type = "Other";
        }

        newExam.isInterTermBreak = exams.achievementForm.isInterTermBreak;
        newExam.weighted_ects =
          ((exams.weightage / 10000) * course.credits) / 100;
        newExam.shortType = exams.achievementAid.shortName;
        return newExam;
      });
      return newExams;
    })
    .flat();

  // group the newCourses by type
  let groupedCourses = newCourses.reduce((acc, curr) => {
    if (acc[curr.type]) {
      acc[curr.type].push(curr);
    } else {
      acc[curr.type] = [curr];
    }
    return acc;
  }, {});

  // flatten every object in groupedCourses
  groupedCourses = Object.keys(groupedCourses).map((key) => {
    return {
      name: key,
      children: groupedCourses[key].map((course) => {
        return {
          name: course.courseName,
          examName: course.shortName,
          type: course.shortType,
          category: key,
          value: course.weighted_ects,
          isCentral: course.isCentral,
        };
      }),
    };
  });

  groupedCourses = { name: "Courses", children: groupedCourses };

  function checkIfCourseOnDay(course) {
    for (let i = 0; i < courseOnDay.length; i++) {
      if (courseOnDay[i] === course.courseNumber) {
        return true;
      }
    }
  }

  function returnEventOfThatDay(course) {
    if (!course.calendarEntry || course.calendarEntry.length === 0) {
      return null;
    }
  
    for (let i = 0; i < course.calendarEntry.length; i++) {
      const entry = course.calendarEntry[i];
      if (!entry.eventDate) {
        continue; // Skip entries with empty eventDate
      }
  
      let event = new Date(entry.eventDate);
      if (
        event.getDate() === hoveredDate?.getDate() &&  // Add null check for hoveredDate
        event.getMonth() === hoveredDate?.getMonth() &&
        event.getFullYear() === hoveredDate?.getFullYear()
      ) {
        let starttimeString = 'N/A';
        let endtimeString = 'N/A';
  
        try {
          starttimeString = event.toLocaleTimeString("locale", {
            hour: "2-digit",
            minute: "2-digit",
          });
          endtimeString = new Date(
            event.getTime() + (entry.durationInMinutes || 0) * 60000
          ).toLocaleTimeString("locale", { hour: "2-digit", minute: "2-digit" });
        } catch (error) {
          console.error("Error formatting time:", error);
        }
  
        return {
          ...entry,
          starttimeString,
          endtimeString,
        };
      }
    }
    return null;
  }

  if (currCourses.length === 0) {
    return (
      <div className="flex items-center justify-center h-full text-sm font-medium text-gray-500 align-middle md:text-base">
        You have no allocated or selected courses.
      </div>
    );
  } else {
    return (
      <div className="w-full px-4 py-5 sm:p-6">
        <Heatmap
          hovered={hoveredCourse}
          setCourseOnDay={setCourseOnDay}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
        />
        <div className="w-full h-full">
          <div className="grid grid-cols-12 gap-4 px-2 py-1 pb-2 text-sm font-semibold text-gray-900 rounded">
            <div className="text-center"></div>
            <div className="col-span-6 truncate">Course</div>

            <div className="col-span-3 truncate ">Classification</div>
            {/* <div className="text-center">Exams</div> */}

            <div className="text-center">Events</div>
            <div className="text-center">ECTS </div>
          </div>
          <div className="inline-block w-full h-full min-w-full align-middle">
            <div className="ring-1 ring-black ring-opacity-5 md:rounded-lg ">
              {currCourses.map((course) => {
                return (
                  <div
                    className={`grid grid-cols-12 gap-4 px-2 py-1 text-sm text-gray-900 rounded group hover:bg-gray-100 ${
                      checkIfCourseOnDay(course) ? "bg-gray-200" : ""
                    }`}
                    onMouseEnter={() => {
                      setHoveredCourse(course);
                    }}
                    onMouseLeave={() => {
                      setHoveredCourse(null);
                    }}
                  >
                    <div className="text-center">
                      <div
                        className={`flex justify-center items-center align-center h-full ${
                          course && course.overlapping
                            ? "border-warning text-warning cursor-pointer"
                            : course.allocated
                            ? " border-main text-main cursor-not-allowed"
                            : course.selected
                            ? "border-main text-main cursor-pointer"
                            : ""
                        }
                        ${
                          course && checkIfCourseOnDay(course)
                            ? "transform scale-110"
                            : ""
                        }
                        `}
                      >
                        {course && course.allocated ? (
                          <LockClosed clg="w-4 h-4 " />
                        ) : (
                          <LockOpen clg="w-4 h-4 " event={course} />
                        )}
                      </div>
                    </div>
                    <div
                      className={`col-span-6 font-semibold truncate cursor-pointer `}
                      onClick={() => courseSelector(course)}
                    >
                      {course.shortName}
                      {course.achievementParts.map((part) => {
                        return (
                          <div className="hidden pt-1 pl-2 text-xs font-thin truncate transition group-hover:block">
                            - {part.achievementForm.shortName}
                          </div>
                        );
                      })}
                      {checkIfCourseOnDay(course) && returnEventOfThatDay(course) ? (
                        <div className="pt-1 text-xs font-thin truncate transition ">
                          from {returnEventOfThatDay(course).starttimeString} to{" "}
                          {returnEventOfThatDay(course).endtimeString}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-span-3">
                      <div className="truncate group-hover:opacity-0">
                        {course.classification}
                      </div>
                      {course.achievementParts.map((part) => {
                        return (
                          <div className="hidden pt-1 text-xs font-thin text-left transition group-hover:block">
                            {part.achievementForm.isCentral
                              ? "Central Exam"
                              : "Decentral Exam"}
                          </div>
                        );
                      })}
                    </div>
                    {/* <div className="text-center">
                      {course.achievementParts.length}
                    </div> */}
                    <div className="text-center">
                      <div className="group-hover:opacity-0">
                        {course.calendarEntry.length}
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="">
                        {(course.credits / 100).toFixed(2)}
                      </div>
                      {course.achievementParts.map((part) => {
                        return (
                          <div className="hidden pt-1 text-xs font-thin text-center transition group-hover:block">
                            {(
                              (part.weightage / 10000) *
                              (course.credits / 100)
                            ).toFixed(2)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 px-2 py-1 pb-2 text-sm font-semibold text-gray-900 rounded">
            <div className="text-center truncate">Total</div>

            <div className="col-span-6 truncate ">
              {currCourses.length} Courses
            </div>
            <div className="col-span-3 text-center"></div>
            <div className="text-center">{totalEvents}</div>
            <div className="text-center">{totalCredits.toFixed(2)} </div>
            <div className="text-center"></div>
          </div>
        </div>
        {/* <Treemap height={500} width={800} data={groupedCourses} /> */}
      </div>
    );
  }
}
