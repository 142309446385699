import React from "react";
import { useRecoilValue } from "recoil";

import { selectedCoursesQuery } from "../../../recoil/selectors/general/selelctedCourses";
import { localCustomGradesState } from "../../../recoil/atoms/custom_grades/localCustomGrades";

import { HeaderElement } from "./HeaderElement";
import { BodyElement } from "./BodyElement";


export const TranscriptTable = ({ row }) => {
  const chosenCoursesOnLoad = useRecoilValue(selectedCoursesQuery);
  const localCustomGrades = useRecoilValue(localCustomGradesState);

  const [chosenCourses, setChosenCourses] = React.useState([]);
  const [structure, setStructure] = React.useState([]);

  /**
   * First effect: filter and flatten the chosen courses.
   */
  React.useEffect(() => {
    // Flatten and filter out only the needed course formats
    let filteredCourses = chosenCoursesOnLoad
      .flat()
      .filter((course) => /.*,1.0.$/.test(course.courseNumber) || /^\d{5}$/.test(course.courseNumber))
      .filter((course) => {
        // Remove allocated courses for a specific semester
        return !(
          course.allocated === true &&
          course.semesterId !== "74db9567-f466-44c3-a9d5-04cbecb0d084"
        );
      });
    setChosenCourses(filteredCourses);
  }, [chosenCoursesOnLoad]);

  /**
   * Second effect: build up the "structure" array from `row`, `chosenCourses`, and `localCustomGrades`.
   */
  React.useEffect(() => {
    if (!row) return; // Guard against null or undefined rows

    // A small utility to safely grab a custom grade (if any)
    function getCustomGradeFor(identifier) {
      return localCustomGrades[identifier] || null;
    }

    /**
     * Recursively build an array of "structure" objects.
     */
    function buildStructure(rootRow) {
      const struct = [];

      /**
       * Recursively parse a row to build a single structure object, then
       * push it into `struct`. If it has child `isTitle` items, recurse.
       */
      function parseItem(item) {
        // Initialize the structure object for this row/item
        const current = {
          name: item.description,
          maxCredits: parseFloat(item.maxCredits),
          minCredits: parseFloat(item.minCredits),
          totalCredits: parseFloat(item.sumOfCredits) || 0,
          addedCredits: 0,
          courses: [],
          children: [],
          directChildren: [],
          isTitle: true,
          gradeSum: 0,
          filteredCredits: parseFloat(item.sumOfCredits) || 0,
          customGradeSum: 0,
          customEctsSum: 0,
        };

        // If the immediate children are actual courses (i.e., isTitle = false)
        if (item.items && item.items[0] && item.items[0].isTitle === false) {
          item.items.forEach((course) => {
            const mark = parseFloat(course.mark) || 0;
            const credits = parseFloat(course.sumOfCredits) || 0;
            const customGrade = getCustomGradeFor(course.shortName);

            current.courses.push({ ...course, customGrade });
            current.gradeSum += mark * credits;

            if (customGrade) {
              current.customGradeSum += customGrade * credits;
              current.customEctsSum += credits;
            }

            // Subtract out "Campus Credits" or "Practice Credits"
            if (["Campus Credits", "Practice Credits"].includes(course.description)) {
              current.filteredCredits -= credits;
            }
          });
        }

        // If the immediate children are *also* isTitle = true, store them to walk them later
        if (item.items && item.items[0] && item.items[0].isTitle === true) {
          item.items.forEach((child) => {
            current.directChildren.push(child);
          });
        }

        // Add any "additional" classification-based courses from chosenCourses
        chosenCourses.forEach((course) => {
          if (course.classification === item.description && !current.courses.includes(course)) {
            const customGrade = getCustomGradeFor(course.courseNumber);

            current.courses.push({ ...course, customGrade });
            current.totalCredits += course.credits / 100;
            current.addedCredits += course.credits / 100;

            if (customGrade) {
              current.customGradeSum += customGrade * (course.credits / 100);
              current.customEctsSum += course.credits / 100;
            }
          }
        });

        // Dive deeper for any child items that are themselves "titles"
        if (item.items) {
          item.items.forEach((child) => {
            if (child.isTitle) {
              current.children.push(child.description);
              parseItem(child);
            }
          });
        }

        // Finally, push this structure object into our array
        struct.push(current);
      }

      // Kick things off for the "root" row
      parseItem(rootRow);

      /**
       * Helper to sum child credits/grades for each structure object
       */
      function sumOfAllChildCredits(item) {
        let sum = 0;
        let gradeSum = 0;
        let filtered = 0;
        let cGradeSum = 0;
        let cEctsSum = 0;

        if (item.children.length > 0) {
          // For each child "title" in children[], find its corresponding struct item
          item.children.forEach((childName) => {
            const childObj = struct.find((s) => s.name === childName);
            if (childObj && childObj.courses.length && childObj.maxCredits > 0) {
              sum += Math.min(childObj.totalCredits, childObj.maxCredits);
              gradeSum += childObj.gradeSum;
              filtered += childObj.filteredCredits;
              cGradeSum += childObj.customGradeSum;
              cEctsSum += childObj.customEctsSum;
            }
          });
        } else if (item.courses.length > 0) {
          sum += Math.min(item.totalCredits, item.maxCredits);
          gradeSum += item.gradeSum;
          filtered += item.filteredCredits;
          cGradeSum += item.customGradeSum;
          cEctsSum += item.customEctsSum;
        }

        return { sum, gradeSum, filtered, cGradeSum, cEctsSum };
      }

      /**
       * Now that `struct` is fully populated, compute final ECTS, averages, etc.
       */
      struct.forEach((item) => {
        const {
          sum,
          gradeSum,
          filtered,
          cGradeSum,
          cEctsSum,
        } = sumOfAllChildCredits(item);

        item.ECTS = Math.min(sum, item.maxCredits);
        item.fullCredits = sum;
        item.avg = filtered ? gradeSum / filtered : 0;

        // Weighted average of "original" plus "custom" grades
        const totalGradeSum = cGradeSum + gradeSum;
        const totalEcts = cEctsSum + filtered;
        item.customGrade = totalEcts ? totalGradeSum / totalEcts : 0;
      });

      return struct;
    }

    // Execute the build, then store in state
    setStructure(buildStructure(row));
  }, [row, chosenCourses, localCustomGrades]);

    // helper function
  const hasContextualStudies = React.useMemo(() => {
    return structure.some(item => item.name === "Contextual Studies");
  }, [structure]);

  if (!row || !structure.length) {
    return null;
  }


  console.log("DEBUG HEADER: structure", structure);
  console.log("DEBUG HEADER: row", row);
  return (
    <div className="p-4 text-sm">
      <HeaderElement 
        isContextualStudies={hasContextualStudies} 
      />
      <BodyElement
        row={row}
        chosenCourses={chosenCourses}
        structure={structure}
      />
    </div>
  );
};